import React from "react";
import HeroSection from "./HeroSection";
import "./HeroSection.css";
import Navbar from "./Navbar";
import AboutMeSection from "./AboutMeSection";
import "./AboutMeSection.css";
import TechSkillsSection from "./TechSkillsSection";
import ProjectsSection from "./ProjectsSection";
import Footer from './Footer';

function App() {
  return (
    <div>
      <Navbar />
      <HeroSection />
      <AboutMeSection />
      <TechSkillsSection />
      <ProjectsSection />
      <Footer />
    </div>
  );
} 

export default App;
